import { Caption1, makeStyles, Subtitle2 } from '@fluentui/react-components';
import { Trans, useTranslation } from 'react-i18next';

import { FormattedCurrency, FormattedDate, Image } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { TransactionIntent } from './models';

export type DuplicateTransactionIntentItemProps = {
    transactionIntent: TransactionIntent;
};

export const DuplicateTransactionIntentItem: React.FC<DuplicateTransactionIntentItemProps> = ({ transactionIntent }) => {
    const classes = useStyles();
    const { t } = useTranslation('portal');

    const amount = (transactionIntent.type === 'PassthroughWithdrawal' ? -1 : 1) * transactionIntent.amount;

    return (
        <div className={classes.root}>
            <Subtitle2>{t('transactionIntent.item.type', { context: transactionIntent.type })}</Subtitle2>
            <Subtitle2 align="end">
                <FormattedCurrency currency={transactionIntent.currency} value={amount} format="sign" />
            </Subtitle2>
            <Caption1 className={classes.details}>
                <Image src="./logo.svg" height={10} fallback={<span>{t('transactionIntent.duplicate.brandName')}</span>} />
            </Caption1>
            <div className={classes.createdAt}>
                <span className={classes.detailsSeparator} />
                <Trans
                    t={t}
                    i18nKey="transactionIntent.duplicate.createdAt"
                    components={{
                        time: <FormattedDate value={transactionIntent.createdOn} format="full-date" />,
                    }}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalL}`,
        borderRadius: tokens.borderRadiusMedium,
        gap: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalM}`,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'baseline',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground5,
    },
    details: {
        gridColumnStart: '1',
        gridColumnEnd: 'span 2',
    },
    detailsSeparator: {
        display: 'inline-block',
        minWidth: '4px',
        width: '4px',
        height: '4px',
        borderRadius: '2px',
        margin: '2px 5px 2px 2px',
        backgroundColor: tokens.colorNeutralForeground1,
    },
    createdAt: {
        fontSize: tokens.fontSizeBase200,
        textAlign: 'start',
        gridColumnStart: '1',
        gridColumnEnd: 'span 2',
    },
    show: {
        fontSize: 'inherit',
        whiteSpace: 'nowrap',
    },
});
