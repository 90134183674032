import { makeStyles } from '@fluentui/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Image, useErrorBoundary } from '@zastrpay/components';

import { merchantLogos } from '../config';
import { get } from './api';

export type MerchantLogoProps = {
    className?: string;
    id?: string;
};

export const MerchantLogo: React.FC<MerchantLogoProps> = ({ id, className }) => {
    const classes = useStyles();
    const { trackError } = useErrorBoundary();

    const logoSrc = useMemo(() => {
        return id && merchantLogos[id];
    }, [id]);

    const [name, setName] = useState(id);

    const getName = useCallback(async () => {
        // id is undefined for some payments like wallet transactions
        if (!id) {
            setName(undefined);
            return;
        }

        try {
            const merchant = await get(id);
            setName(merchant.displayName ?? merchant.name);
        } catch {
            // not a critical error, we just show merchantId as fallback
        }
    }, [id]);

    useEffect(() => {
        // id is undefined for some payments like wallet transactions
        if (id !== undefined && logoSrc === undefined) {
            trackError(`Logo missing for merchant with id ${id}`);

            getName();
        }
    }, [trackError, getName, id, logoSrc]);

    return name || logoSrc ? (
        <Image src={logoSrc} className={className} fallback={<span className={classes.name}>{name}</span>} onError={getName} />
    ) : null;
};

const useStyles = makeStyles({
    name: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
    },
});
