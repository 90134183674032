import { api, getListDeserialized, getPagedList, List, PAGE_SIZE, PagedList, Serialized } from '@zastrpay/common';

import { endpoints } from '../config';
import { Transaction } from './models';

const deserializeTransaction = (transaction: Serialized<Transaction>): Transaction => ({
    ...transaction,
    createdOn: new Date(transaction.createdOn),
});

type FilterParams = {
    _limit: number;
    _order: string;
    createdOn_lte?: string;
    createdOn_gte?: string;
    customerId: string;
};

export const get = async (customerId: string, from?: Date, to?: Date, count = PAGE_SIZE): Promise<PagedList<Transaction>> => {
    const params: FilterParams = {
        customerId,
        _order: to || !from ? 'desc' : 'asc',
        _limit: count + 1,
    };

    const fromCreatedOn = !to && from ? from : undefined;

    params.createdOn_gte = fromCreatedOn?.toISOString();
    params.createdOn_lte = to?.toISOString();

    return api
        .get<List<Transaction>>(`${endpoints.trxQryh}/transactions/filters`, { params })
        .then(getPagedList(from, params._limit))
        .then(getListDeserialized(deserializeTransaction));
};
