import { ClockDismissRegular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { trackPage } from '@zastrpay/analytics';
import { useReloadObserver } from '@zastrpay/hooks';
import { MessagePage } from '@zastrpay/layout';

export const SessionExpired: React.FC = () => {
    const navigate = useNavigate();

    const { t } = useTranslation('portal');

    useEffect(() => {
        trackPage('error_session_expired', { type: 'error' });
    }, []);

    // on reload we redirect back to root
    useReloadObserver(() => navigate('/login', { replace: true }));

    return (
        <MessagePage
            icon={<ClockDismissRegular />}
            title={t('session.expired.title')}
            message={t('session.expired.subTitle')}
            action={t('session.expired.goToLogin')}
            onAction={() => navigate('/login')}
        />
    );
};
