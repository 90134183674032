import { Body1, Body2, Card, makeStyles, Subtitle1 } from '@fluentui/react-components';
import { ArrowSwapRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { Link } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { get } from './api';
import { Transaction } from './models';
import { TransactionItem } from './TransactionItem';

const TRANSACTION_COUNT = 3;

export const TransactionWidget: React.FC = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const { state, customerId } = useAuth();
    const { t } = useTranslation('portal');

    const [transactions, setTransactions] = useState<Transaction[]>();

    useEffect(() => {
        if (state === 'authenticated') {
            get(customerId, undefined, undefined, TRANSACTION_COUNT).then((page) => setTransactions(page.items));
        }
    }, [state, customerId]);

    if (!transactions) {
        return null;
    }

    return (
        <Card size="large">
            <div className={classes.content}>
                <ArrowSwapRegular className={classes.icon} />
                <div className={classes.header}>
                    <Subtitle1 block className={classes.title}>
                        {t('transaction.widget.title')}
                    </Subtitle1>
                    <Body1>{t('transaction.widget.subTitle')}</Body1>
                </div>

                {transactions.map((transaction) => (
                    <TransactionItem key={transaction.id} transaction={transaction} />
                ))}

                {transactions.length === 0 ? (
                    <Body2 className={classes.info}>{t('transaction.widget.empty')}</Body2>
                ) : (
                    <div className={classes.info}>
                        <Link onClick={() => navigate('/transactions')}>{t('transaction.widget.more')}</Link>
                    </div>
                )}
            </div>
        </Card>
    );
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: tokens.spacingVerticalM,
    },
    header: {
        flex: '1 1 50%',
    },
    title: {
        color: tokens.colorBrandForeground1,
    },
    icon: {
        fontSize: tokens.fontSizeHero900,
    },
    info: {
        marginTop: tokens.spacingVerticalXS,
        width: '100%',
        textAlign: 'center',
    },
});
