import { useEffect, useLayoutEffect } from 'react';

import { trackPage } from '@zastrpay/analytics';
import { ActiveKycRequest } from '@zastrpay/kyc-requests';
import { Page, useLayout } from '@zastrpay/layout';

import { BalanceWidget } from '../accounts/BalanceWidget';
import { TransactionIntentWidget } from '../transaction-intents/TransactionIntentWidget';
import { TransactionWidget } from '../transactions/TransactionWidget';
import { CustomerBlockedAlert } from './CustomerBlockedAlert';

export const Home: React.FC = () => {
    const { setBackground } = useLayout();

    useEffect(() => {
        trackPage('home');
    }, []);

    useLayoutEffect(() => {
        setBackground('subtle');
        return () => setBackground('default');
    }, [setBackground]);

    return (
        <Page align="fill">
            <ActiveKycRequest mode="alert" />
            <CustomerBlockedAlert />

            <BalanceWidget />

            <TransactionIntentWidget />
            <TransactionWidget />
        </Page>
    );
};
