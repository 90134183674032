import { useEffect } from 'react';

export const useReloadObserver = (callback: () => void) => {
    useEffect(() => {
        const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming | undefined;

        // if the current time is very close to the domContentLoaded we can assume it's a real reload
        // and not just the initial load entry...
        if (navigation?.type === 'reload' && Math.abs(performance.now() - navigation.domContentLoadedEventEnd) < 1500) {
            callback();
        }
    }, []);
};
