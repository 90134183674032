import React, { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useRef, useState } from 'react';

type ImageProps = {
    placeholder?: React.ReactNode;
    fallback?: React.ReactNode;
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const Image: React.FC<ImageProps> = ({ fallback, placeholder, onError, onLoad, ...props }) => {
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setLoaded(() => !!imageRef.current?.complete);
    }, []);

    const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setError(true);
        onError?.(e);
    };

    const handleLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setLoaded(true);
        onError?.(e);
    };

    if (fallback && (error || !props.src)) {
        return fallback;
    }

    if (!loaded && placeholder) {
        return placeholder;
    }

    // eslint-disable-next-line jsx-a11y/alt-text
    return <img ref={imageRef} {...props} onError={handleError} onLoad={handleLoad} />;
};
