import { makeStyles } from '@fluentui/react-components';
import { ChevronRightRegular } from '@fluentui/react-icons';
import { ReactElement } from 'react';

import { Body } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export type SettingsMenuItemProps = {
    icon: ReactElement;
    title: string;
    onClick?: () => void;
};

export const SettingsMenuItem: React.FC<SettingsMenuItemProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root} onClick={props.onClick}>
            <div className={classes.icon}>{props.icon}</div>
            <div className={classes.text}>
                <Body className={classes.title}>{props.title}</Body>
            </div>
            <div className={classes.arrow}>
                <ChevronRightRegular />
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: '32px auto 32px',
        alignItems: 'center',
        justifyItems: 'center',
        gap: tokens.spacingHorizontalS,
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalXS} ${tokens.spacingVerticalM} ${tokens.spacingHorizontalS}`,
        transition: `background-color ${tokens.durationNormal} ${tokens.curveEasyEase}`,
        borderRadius: tokens.borderRadiusMedium,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
            cursor: 'pointer',
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
            cursor: 'pointer',
        },
    },
    text: {
        justifySelf: 'start',
        gridColumnStart: '2',
    },
    icon: {
        fontSize: tokens.fontSizeHero700,
    },
    arrow: {
        fontSize: tokens.fontSizeHero700,
        color: tokens.customPaletteGreenDark,
    },
    title: {
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightSemibold,
        display: 'block',
        textAlign: 'start',
    },
});
