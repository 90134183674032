import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { BackButton, useLayout } from '@zastrpay/layout';
import { Support as SharedSupport } from '@zastrpay/pages';

import { HeaderMenu } from '../layout/HeaderMenu';

export const Support: React.FC = () => {
    const navigate = useNavigate();

    const { setHeaderSlot, clearHeaderSlot } = useLayout();

    const { state } = useAuth();

    useEffect(() => {
        trackPage('support_page');
    }, []);

    useEffect(() => {
        setHeaderSlot('left', <BackButton onClick={() => navigate(-1)} />);
        if (state === 'authenticated') {
            setHeaderSlot('right', <HeaderMenu />);
        } else {
            clearHeaderSlot('right');
        }

        return () => clearHeaderSlot('left');
    }, [navigate, setHeaderSlot, clearHeaderSlot, state]);

    return <SharedSupport />;
};
