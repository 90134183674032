import { Caption1, makeStyles, mergeClasses, Subtitle2 } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { FormattedCurrency, FormattedDate } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { Transaction } from './models';

export type TransactionItemProps = {
    transaction: Transaction;
};

export const TransactionItem: React.FC<TransactionItemProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('portal');

    return (
        <div className={classes.root}>
            <Subtitle2>{t('transaction.item.type', { context: props.transaction.type })}</Subtitle2>
            <Subtitle2 align="end" data-clarity-mask="true">
                {props.transaction.type === 'PassthroughWithdrawal' && '-'}
                <FormattedCurrency value={props.transaction.amount} currency={props.transaction.currency} format="sign" />
            </Subtitle2>
            <Caption1 className={classes.details}>
                <FormattedDate value={props.transaction.createdOn} format="full-date" />
                <span className={classes.detailsSeparator} />
                <span
                    className={mergeClasses(
                        props.transaction.status === 'Failed' && classes.failed,
                        props.transaction.status === 'Succeeded' && classes.succeeded,
                    )}
                >
                    {t('transaction.item.status', { context: props.transaction.status })}
                </span>
            </Caption1>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalL}`,
        borderRadius: tokens.borderRadiusMedium,
        gap: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalM}`,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground5,
    },
    details: {
        gridColumnStart: '1',
        gridColumnEnd: 'span 2',
    },
    detailsSeparator: {
        display: 'inline-block',
        width: '4px',
        height: '4px',
        borderRadius: '2px',
        margin: '2px 8px',
        backgroundColor: tokens.colorNeutralForeground1,
    },
    failed: {
        color: tokens.customPaletteRed,
    },
    succeeded: {
        color: tokens.customPaletteGreen,
    },
});
