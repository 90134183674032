import React from 'react';

import { CookieBanner } from '@zastrpay/analytics';
import { Layout as SharedLayout } from '@zastrpay/layout';

export const Layout: React.FC = () => {
    return (
        <>
            <SharedLayout />
            <CookieBanner />
        </>
    );
};
