import { DismissCircleRegular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { trackPage } from '@zastrpay/analytics';
import { useReloadObserver } from '@zastrpay/hooks';
import { MessagePage } from '@zastrpay/layout';

export const GeneralError: React.FC = () => {
    const navigate = useNavigate();

    const { t } = useTranslation('portal');

    useEffect(() => {
        trackPage('error_general_error', { type: 'error' });
    }, []);

    // on reload we redirect back to root
    useReloadObserver(() => navigate('/', { replace: true }));

    return <MessagePage icon={<DismissCircleRegular />} title={t('common.error.title')} message={t('common.error.subTitle')} />;
};
