import { makeStyles, mergeClasses } from '@fluentui/react-components';

import { tokens } from '@zastrpay/theme';

export type HeaderMenuSeparatorProps = {
    className?: string;
};
export const HeaderMenuSeparator: React.FC<HeaderMenuSeparatorProps> = (props) => {
    const classes = useStyles();

    return <div className={mergeClasses(classes.root, props.className)} />;
};

const useStyles = makeStyles({
    root: {
        borderBottom: `1px solid ${tokens.colorNeutralStroke2}`,
    },
});
