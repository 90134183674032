import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ReactElement } from 'react';

import { Body } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export type HeaderMenuItemProps = {
    icon?: ReactElement;
    title: string | ReactElement;
    description?: string;
    onClick?: () => void;
    className?: string;
};

export const HeaderMenuItem: React.FC<HeaderMenuItemProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={mergeClasses(classes.root, props.className)} onClick={props.onClick}>
            {props.icon}
            <div className={classes.text}>
                <Body align="start" className={classes.title}>
                    {props.title}
                </Body>
                {props.description && (
                    <Body align="start" className={classes.description}>
                        {props.description}
                    </Body>
                )}
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: '32px 1fr',
        alignItems: 'center',
        justifyItems: 'center',
        gap: `0 ${tokens.spacingHorizontalS}`,
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalL}`,
        transition: `background-color ${tokens.durationNormal} ${tokens.curveEasyEase}`,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
            cursor: 'pointer',
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
            cursor: 'pointer',
        },
    },
    text: {
        justifySelf: 'start',
        gridColumnStart: '2',
    },
    title: {
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightSemibold,
        display: 'block',
    },
    description: {
        fontSize: tokens.fontSizeBase200,
    },
});
