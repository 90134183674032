import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ArrowSwap24Regular, Home24Regular, Location24Regular } from '@fluentui/react-icons';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { trackClick } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { Button } from '@zastrpay/components';
import { enforced, useKycRequest } from '@zastrpay/kyc-requests';
import { tokens } from '@zastrpay/theme';

import { DuplicateTransactionIntentDialog } from '../transaction-intents/DuplicateTransactionIntentDialog';
import { BottomMenuItem } from './BottomMenuItem';

const BOTTOM_MENU_HEIGHT = '56px';

export type BottomMenuProps = {
    className?: string;
    walletActions?: boolean;
};

export const BottomMenu: React.FC<BottomMenuProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation('portal');
    const navigate = useNavigate();
    const [intentPage, setIntentPage] = useState('');
    const { customer } = useAuth();

    const { all: allKycRequests } = useKycRequest();

    const [showDuplicateIntentPreventionDialog, setShowDuplicateIntentPreventionDialog] = useState(false);

    const walletActionsDisabled = useMemo(() => {
        if (allKycRequests.some(enforced)) {
            return { withdrawal: true, deposit: true };
        }
        return {
            withdrawal: customer?.permissions.executePassthroughWithdrawal === 'Disallowed',
            deposit: customer?.permissions.executePassthroughDeposit === 'Disallowed',
        };
    }, [allKycRequests, customer]);

    const onWithdrawClick = () => {
        trackClick('home', 'withdraw');
        setIntentPage('/transaction-intent/withdraw');
        setShowDuplicateIntentPreventionDialog(true);
    };

    const onDepositClick = () => {
        trackClick('home', 'deposit');
        setIntentPage('/transaction-intent/deposit');
        setShowDuplicateIntentPreventionDialog(true);
    };

    const proceedToIntentPage = useCallback(() => {
        setShowDuplicateIntentPreventionDialog(false);
        navigate(intentPage);
    }, [navigate, intentPage, setShowDuplicateIntentPreventionDialog]);

    const closeDuplicateTransactionIntentDialog = useCallback(() => {
        setShowDuplicateIntentPreventionDialog(false);
    }, [setShowDuplicateIntentPreventionDialog]);

    return (
        <div className={mergeClasses(classes.root, props.className)}>
            {props.walletActions && (
                <div className={classes.action}>
                    <Button appearance="secondary" size="large" disabled={walletActionsDisabled.withdrawal} onClick={onWithdrawClick}>
                        {t('home.withdraw')}
                    </Button>
                    <Button appearance="primary" size="large" disabled={walletActionsDisabled.deposit} onClick={onDepositClick}>
                        {t('home.deposit')}
                    </Button>
                </div>
            )}
            <div className={classes.menu}>
                <BottomMenuItem icon={<Home24Regular />} title={t('menu.bottom.home')} href="/" />
                <BottomMenuItem icon={<ArrowSwap24Regular />} title={t('menu.bottom.transactions')} href="/transactions" />
                <BottomMenuItem icon={<Location24Regular />} title={t('menu.bottom.shops')} href="/shops" />
            </div>
            <DuplicateTransactionIntentDialog
                show={showDuplicateIntentPreventionDialog}
                onBack={closeDuplicateTransactionIntentDialog}
                onError={closeDuplicateTransactionIntentDialog}
                onContinue={proceedToIntentPage}
            />
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground1,
        borderTop: `1px solid ${tokens.colorNeutralBackground4}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    action: {
        maxWidth: tokens.customPageWidth,
        width: '90%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalL,
        gap: tokens.spacingHorizontalL,
    },
    menu: {
        height: BOTTOM_MENU_HEIGHT,
        maxWidth: tokens.customPageWidth,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
    },
});
