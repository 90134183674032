import { api, getListDeserialized, PagedList, Serialized } from '@zastrpay/common';

import { endpoints } from '../config';
import { TransactionIntent, TransactionIntentFilter } from './models';

const SERVICE_PREFIX = 'transaction-intents';
const SERVICE_QRY_URL = `${endpoints.trxQryh}/${SERVICE_PREFIX}`;

type FilterParams = {
    queryType: string;
    customerId?: string;
    merchantId?: string;
    type?: string;
};

const deserializeTransactionIntent = (transactionIntent: Serialized<TransactionIntent>): TransactionIntent => {
    return {
        ...transactionIntent,
        createdOn: new Date(transactionIntent.createdOn),
    };
};

export const getList = (filter: TransactionIntentFilter): Promise<PagedList<TransactionIntent>> => {
    const params: FilterParams = {
        queryType: filter.type,
    };

    if (filter.type === 'ActiveByCustomer') {
        params.customerId = filter.customerId;
    }

    return api.get<PagedList<TransactionIntent>>(`${SERVICE_QRY_URL}`, { params }).then(getListDeserialized(deserializeTransactionIntent));
};

export const get = (id: string): Promise<TransactionIntent> => {
    return api.get<TransactionIntent>(`${SERVICE_QRY_URL}/${id}`).then(deserializeTransactionIntent);
};

export const cancel = (id: string): Promise<TransactionIntent> => {
    return api.post<TransactionIntent>(`${endpoints.trxCmdh}/transaction-intents/${id}/cancel`).then(deserializeTransactionIntent);
};
