import { Card, makeStyles, Subtitle1 } from '@fluentui/react-components';
import { QrCodeFilled } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@zastrpay/auth';
import { tokens } from '@zastrpay/theme';

import { getList } from './api';
import { TransactionIntent } from './models';
import { TransactionIntentItem } from './TransactionIntentItem';

export const TransactionIntentWidget: React.FC = () => {
    const classes = useStyles();

    const { state, customerId } = useAuth();
    const { t } = useTranslation('portal');

    const [transactionIntents, setTransactionIntents] = useState<TransactionIntent[]>();

    useEffect(() => {
        if (state === 'authenticated') {
            getList({ customerId, type: 'ActiveByCustomer' }).then((intents) => {
                // TODO: at some point approval should also be possible from portal
                setTransactionIntents(intents.items.filter((intent) => intent.state === 'Pending'));
            });
        }
    }, [state, customerId]);

    if (!transactionIntents?.length) {
        return null;
    }

    return (
        <Card size="large">
            <div className={classes.content}>
                <QrCodeFilled className={classes.icon} />
                <div>
                    <Subtitle1 block className={classes.title}>
                        {t('transactionIntent.widget.title')}
                    </Subtitle1>
                </div>

                {transactionIntents.map((transactionIntent) => (
                    <TransactionIntentItem key={transactionIntent.id} transactionIntent={transactionIntent} />
                ))}
            </div>
        </Card>
    );
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: tokens.spacingVerticalM,
    },
    title: {
        color: tokens.colorBrandForeground1,
    },
    icon: {
        fontSize: tokens.fontSizeHero900,
    },
    info: {
        marginTop: tokens.spacingVerticalXS,
        width: '100%',
        textAlign: 'center',
    },
});
