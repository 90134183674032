import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { tokens } from '@zastrpay/theme';

export type BottomMenuItemProps = {
    icon: ReactElement;
    title: string;
    href: string;
};

export const BottomMenuItem: React.FC<BottomMenuItemProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();

    const clicked = (event: React.MouseEvent) => {
        event.preventDefault();

        navigate(props.href);
    };

    return (
        <a href={props.href} onClick={clicked} className={mergeClasses(classes.root, props.href === location.pathname && classes.active)}>
            <span className={classes.content}>
                {props.icon}
                <span className={classes.title}>{props.title}</span>
            </span>
        </a>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: tokens.colorNeutralForeground3,
        textDecoration: 'none',
        ':active': {
            color: tokens.customPaletteGreen,
        },
    },
    active: {
        color: tokens.customPaletteGreen,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
    },
});
