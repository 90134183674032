import { lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useKycRequest } from '@zastrpay/kyc-requests';
import { BackButton, useLayout } from '@zastrpay/layout';

const RegistrationCode = lazy(() => import('@zastrpay/registration').then((module) => ({ default: module.RegistrationCode })));

export const RegistrationFinalize: React.FC = () => {
    const navigate = useNavigate();
    const { setHeaderSlot, clearHeaderSlot } = useLayout();
    const { submit, complete } = useKycRequest();

    const completeRegistration = () => {
        submit();
        complete();
        navigate('/');
    };

    useEffect(() => {
        setHeaderSlot('left', <BackButton onClick={() => navigate(-1)} />);

        return () => clearHeaderSlot('left');
    }, [navigate, setHeaderSlot, clearHeaderSlot]);

    return <RegistrationCode onComplete={completeRegistration} />;
};
