import { Body1, Card, makeStyles, Subtitle1 } from '@fluentui/react-components';
import { ArrowSwapRegular } from '@fluentui/react-icons';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { Link } from '@zastrpay/components';
import { Page, useLayout } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { get } from './api';
import { Transaction } from './models';
import { TransactionItem } from './TransactionItem';

export const TransactionList: React.FC = () => {
    const classes = useStyles();

    const { state, customerId } = useAuth();
    const { t } = useTranslation('portal');

    const { setBackground } = useLayout();

    const [transactions, setTransactions] = useState<Transaction[]>();
    const [next, setNext] = useState<Transaction>();

    useEffect(() => {
        trackPage('transaction_list');
    }, []);

    useLayoutEffect(() => {
        setBackground('subtle');
        return () => setBackground('default');
    }, [setBackground]);

    useEffect(() => {
        if (state === 'authenticated') {
            get(customerId).then(({ items, next }) => {
                setTransactions(items);
                setNext(next);
            });
        }
    }, [state, customerId]);

    const nextPage = (next: Transaction) => {
        if (customerId) {
            get(customerId, undefined, next.createdOn).then(({ items, next }) => {
                setTransactions((transactions) => transactions && [...transactions, ...items]);
                setNext(next);
            });
        }
    };

    if (!transactions) {
        return null;
    }

    return (
        <Page align="fill">
            <Card size="large">
                <div className={classes.content}>
                    <ArrowSwapRegular className={classes.icon} />
                    <Subtitle1 block className={classes.title}>
                        {t('transaction.list.title')}
                    </Subtitle1>

                    {transactions.map((transaction) => (
                        <TransactionItem key={transaction.id} transaction={transaction} />
                    ))}

                    {transactions.length === 0 ? (
                        <Body1 className={classes.info}>{t('transaction.list.empty')}</Body1>
                    ) : (
                        next && (
                            <div className={classes.info}>
                                <Link onClick={() => nextPage(next)}>{t('transaction.list.more')}</Link>
                            </div>
                        )
                    )}
                </div>
            </Card>
        </Page>
    );
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: tokens.spacingHorizontalM,
    },
    title: {
        color: tokens.colorBrandForeground1,
    },
    icon: {
        fontSize: tokens.fontSizeHero900,
    },
    info: {
        marginTop: tokens.spacingVerticalXS,
        width: '100%',
        textAlign: 'center',
    },
});
