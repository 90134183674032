import { bool, required } from '@zastrpay/common';

export const endpoints = {
    accQryh: required(import.meta.env.VITE_ACC_SVC_QRYH_URL, 'VITE_ACC_SVC_QRYH_URL'),
    trxCmdh: required(import.meta.env.VITE_TRX_SVC_CMDH_URL, 'VITE_TRX_SVC_CMDH_URL'),
    trxQryh: required(import.meta.env.VITE_TRX_SVC_QRYH_URL, 'VITE_TRX_SVC_QRYH_URL'),
    custQryh: required(import.meta.env.VITE_CUST_SVC_QRYH_URL, 'VITE_CUST_SVC_QRYH_URL'),
    merchQryh: required(import.meta.env.VITE_MERCH_SVC_QRYH_URL, 'VITE_MERCH_SVC_QRYH_URL'),
};

export const applicationInsights = {
    instrumentationKey: required(import.meta.env.VITE_APPINSIGHTS_KEY, 'VITE_APPINSIGHTS_KEY'),
    enabled: bool(import.meta.env.VITE_APPINSIGHTS_ENABLED),
};

export const requestConfigs = {
    retries: Number(import.meta.env.VITE_REQUEST_RETRIES ?? 3),
    delay: Number(import.meta.env.VITE_REQUEST_RETRY_DELAY ?? 1000),
};

export const merchantLogos: Record<string, string> = Object.fromEntries(
    (import.meta.env.VITE_MERCHANT_LOGOS as string).split(';').map((e) => e.split('=')),
);

export const featureToggles = {};
