import { makeStyles } from '@fluentui/react-components';
import { ErrorCircleRegular, ShieldLockRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { Body, Dialog } from '@zastrpay/components';
import { getWhitelistedMerchants, updateWhitelistedMerchants } from '@zastrpay/customers';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { get as getMerchant } from '../merchants/api';
import { Merchant } from '../merchants/models';
import { SettingsHeader } from './SettingsHeader';
import { TrustedBeneficiaryItem } from './TrustedBeneficiaryItem';

export const TrustedBeneficiaryList = () => {
    const classes = useStyles();

    const { t } = useTranslation('portal');
    const { state, customerId } = useAuth();

    const [merchantToRemove, setMerchantToRemove] = useState<Merchant>();
    const [merchants, setMerchants] = useState<string[]>();

    useEffect(() => {
        trackPage('trusted_beneficiaries');
    }, []);

    useEffect(() => {
        if (state === 'authenticated') {
            getWhitelistedMerchants(customerId).then(setMerchants);
        }
    }, [customerId, state]);

    const cancelRemove = () => {
        setMerchantToRemove(undefined);
    };

    const confirmRemove = async (id: string) => {
        trackPage('trusted_beneficiaries_approve_removal');

        const merchant = await getMerchant(id);

        setMerchantToRemove(merchant);
    };

    const remove = async () => {
        if (state === 'authenticated' && merchants && merchantToRemove) {
            trackClick('trusted_beneficiaries', 'remove');

            const remainingMerchants = merchants.filter((id) => id !== merchantToRemove.id);
            const updatedMerchants = await updateWhitelistedMerchants(customerId, remainingMerchants);

            setMerchants(updatedMerchants);
            setMerchantToRemove(undefined);
        }
    };

    return (
        <Page align="fill">
            <SettingsHeader title={t('settings.trustedBeneficiaries.title')} icon={<ShieldLockRegular />} />

            {merchants?.length === 0 && <Body>{t('settings.trustedBeneficiaries.empty')}</Body>}
            {merchants?.map((id) => <TrustedBeneficiaryItem key={id} id={id} onDelete={() => confirmRemove(id)} />)}

            <Dialog
                open={!!merchantToRemove}
                onOpenChange={cancelRemove}
                align="bottom"
                dismissible={false}
                icon={<ErrorCircleRegular className={classes.dialogIcon} />}
                title={t('settings.trustedBeneficiaries.removeConfirmation.title')}
                actions={[
                    { text: t('settings.trustedBeneficiaries.removeConfirmation.yes'), onClick: remove },
                    { text: t('settings.trustedBeneficiaries.removeConfirmation.no'), style: 'preferred', onClick: cancelRemove },
                ]}
            >
                <Body block>
                    {t('settings.trustedBeneficiaries.removeConfirmation.message', {
                        merchant: merchantToRemove?.displayName ?? merchantToRemove?.name,
                    })}
                </Body>
            </Dialog>
        </Page>
    );
};

const useStyles = makeStyles({
    dialogIcon: {
        color: tokens.customPaletteRed,
    },
});
