import { makeStyles } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { CloseButton, useLayout } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { BottomMenu } from './BottomMenu';
import { HeaderMenu } from './HeaderMenu';
import { Layout } from './Layout';

type AuthenticatedLayoutProps = { bottomMenu?: boolean; userMenu?: boolean; walletActions?: boolean; onCancel?: () => void };

export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({ bottomMenu, userMenu, walletActions, onCancel }) => {
    const classes = useStyles();
    const location = useLocation();

    const { state: authState, customer } = useAuth();
    const { setHeaderSlot, clearHeaderSlot } = useLayout();

    useEffect(() => {
        if (onCancel) {
            setHeaderSlot('right', <CloseButton onClick={onCancel} />);
        } else if (authState === 'authenticated' && customer && userMenu) {
            setHeaderSlot('right', <HeaderMenu />);
        } else {
            clearHeaderSlot('right');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, userMenu, customer, setHeaderSlot, clearHeaderSlot, location]);

    if (authState === 'anonymous') {
        return <Navigate to="/login" />;
    } else if (authState === 'authenticated' && !customer) {
        return <Navigate to="/register" />;
    }

    return (
        <>
            <Layout />
            {bottomMenu && <BottomMenu className={classes.bottomMenu} walletActions={walletActions} />}
        </>
    );
};

const useStyles = makeStyles({
    bottomMenu: {
        position: 'sticky',
        bottom: tokens.customFooterPadding,
        left: 0,
        right: 0,
    },
});
