import { makeStyles } from '@fluentui/react-components';
import { InfoRegular, PersonRegular } from '@fluentui/react-icons';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { register } from '@zastrpay/common';
import { InputField } from '@zastrpay/components';
import { useCountries } from '@zastrpay/hooks';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { SettingsHeader } from './SettingsHeader';

export type PersonalDetailsData = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    placeOfBirth: string;
    countryOfBirth: string;
    nationality: string;
};

export const PersonalDetails = () => {
    const classes = useStyles();
    const { t } = useTranslation('portal');

    const { countries } = useCountries();
    const { customer } = useAuth();

    const countryOfBirth = useMemo(() => {
        return countries.find((c) => c.countryCode === customer?.countryOfBirth);
    }, [countries, customer?.countryOfBirth]);

    const nationality = useMemo(() => {
        return countries.find((c) => c.countryCode === customer?.nationality);
    }, [countries, customer?.nationality]);

    const { control } = useForm<PersonalDetailsData>({
        defaultValues: {
            firstName: customer?.firstName,
            lastName: customer?.lastName,
            dateOfBirth: customer?.dateOfBirth,
            placeOfBirth: customer?.placeOfBirth,
            countryOfBirth: countryOfBirth?.name,
            nationality: nationality?.name,
        },
    });

    useEffect(() => {
        trackPage('personal_details');
    }, []);

    return (
        <Page align="fill">
            <SettingsHeader title={t('settings.personalDetails.title')} icon={<PersonRegular />} />

            <InputField
                type="text"
                {...register(control, 'firstName')}
                label={t('settings.personalDetails.firstName')}
                size={'large'}
                disabled
            />
            <InputField
                type="text"
                {...register(control, 'lastName')}
                label={t('settings.personalDetails.lastName')}
                size={'large'}
                disabled
            />
            <InputField
                type="text"
                {...register(control, 'dateOfBirth')}
                label={t('settings.personalDetails.dateOfBirth')}
                size={'large'}
                disabled
            />
            <InputField
                type="text"
                {...register(control, 'placeOfBirth')}
                label={t('settings.personalDetails.placeOfBirth')}
                hint={{
                    className: classes.hint,
                    children: (
                        <>
                            <InfoRegular className={classes.hintIcon} />
                            {t('settings.personalDetails.placeOfBirthHint')}
                        </>
                    ),
                }}
                size={'large'}
                disabled
            />
            <InputField
                type="text"
                {...register(control, 'countryOfBirth')}
                label={t('settings.personalDetails.countryOfBirth')}
                size={'large'}
                disabled
            />
            <InputField
                type="text"
                {...register(control, 'nationality')}
                label={t('settings.personalDetails.nationality')}
                size={'large'}
                disabled
            />
        </Page>
    );
};

const useStyles = makeStyles({
    hint: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalXS,
    },
    hintIcon: {
        color: tokens.customPaletteBlue,
    },
});
