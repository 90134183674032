import { Avatar, makeStyles, Popover, PopoverProps, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import { ChatHelp24Regular, PersonCircleRegular, Settings24Regular, SignOut24Regular } from '@fluentui/react-icons';
import { Fragment, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { trackClick } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { LANGUAGES } from '@zastrpay/common';
import { tokens } from '@zastrpay/theme';

import { HeaderMenuItem } from './HeaderMenuItem';
import { HeaderMenuSeparator } from './HeaderMenuSeparator';

export const HeaderMenu: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const { phone, logout, customer } = useAuth();

    const { t, i18n } = useTranslation('portal');

    const [open, setOpen] = useState(false);

    const openChanged: PopoverProps['onOpenChange'] = (e, data) => {
        setOpen(data.open || false);
    };

    const handleLogout = () => {
        trackClick('profile_menu', 'logout');
        logout();
    };
    const changeLanguage = (lang: { code: string }) => {
        trackClick('profile_menu', 'change_language', { toLanguage: lang.code });
        i18n.changeLanguage(lang.code);
    };

    return (
        <Popover open={open} onOpenChange={openChanged} positioning={{ position: 'below', align: 'end', offset: { mainAxis: 8 } }}>
            <PopoverTrigger disableButtonEnhancement>
                <Avatar
                    className={classes.avatar}
                    initials={{ className: classes.avatarBackground }}
                    name={`${customer?.firstName} ${customer?.lastName}`}
                />
            </PopoverTrigger>

            <PopoverSurface className={classes.menu} onClick={() => setOpen(false)}>
                <HeaderMenuItem
                    icon={<PersonCircleRegular className={classes.user} />}
                    title={
                        <span data-clarity-mask="true">
                            {customer?.firstName} {customer?.lastName}
                        </span>
                    }
                    description={phone}
                />

                {LANGUAGES.filter((l) => l.code !== i18n.language).map((lang) => (
                    <Fragment key={lang.code}>
                        <HeaderMenuSeparator />
                        <HeaderMenuItem
                            title={
                                <>
                                    <ReactCountryFlag countryCode={lang.countryCode} svg className={classes.flag} />
                                    {lang.name}
                                </>
                            }
                            description={lang.change}
                            onClick={() => changeLanguage(lang)}
                        />
                    </Fragment>
                ))}

                <HeaderMenuSeparator />
                <HeaderMenuItem icon={<Settings24Regular />} title={t('menu.top.settings')} onClick={() => navigate('/settings')} />
                <HeaderMenuItem icon={<ChatHelp24Regular />} title={t('menu.top.contact')} onClick={() => navigate('/support')} />
                <HeaderMenuItem icon={<SignOut24Regular />} title={t('menu.top.logOut')} onClick={handleLogout} />
            </PopoverSurface>
        </Popover>
    );
};

const useStyles = makeStyles({
    avatar: {
        border: `1px solid ${tokens.colorBrandStroke1}`,
        height: '30px',
        width: '30px',
    },
    avatarBackground: {
        backgroundColor: tokens.colorNeutralBackground1,
        lineHeight: 'unset',
    },
    menu: {
        padding: '0',
        overflow: 'hidden',
        minWidth: '240px',
    },
    user: {
        fontSize: tokens.fontSizeHero900,
        margin: '-4px 0 -4px -8px',
    },
    flag: {
        marginRight: tokens.spacingHorizontalS,
        marginTop: '-3px',
        overflow: 'hidden',
        borderRadius: tokens.borderRadiusSmall,
        width: 'auto !important',
        height: '0.8em !important',
    },
});
