import { useEffect } from 'react';

import { isNetworkError, isResponseError } from '@zastrpay/common';
import { Header } from '@zastrpay/layout';

import { GeneralError } from './GeneralError';
import { NetworkError } from './NetworkError';
import { SessionExpired } from './SessionExpired';

export type UnhandledErrorProps = {
    error: unknown;
    reset: () => void;
};

export const UnhandledError: React.FC<UnhandledErrorProps> = ({ error, reset }) => {
    useEffect(() => {
        const navigateRoot = () => {
            window.history.replaceState({}, '', '/');
            // reset the error after url change so the new url can get rendered
            reset();
        };

        window.addEventListener('popstate', navigateRoot);

        return () => window.removeEventListener('popstate', navigateRoot);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            {isNetworkError(error) ? <NetworkError /> : isResponseError(error, 'Unauthorized') ? <SessionExpired /> : <GeneralError />}
        </>
    );
};
