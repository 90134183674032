import { makeStyles } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';

import { tokens } from '@zastrpay/theme';

import { MerchantLogo } from '../merchants/MerchantLogo';

export type TrustedBeneficiaryItemProps = {
    id: string;
    onDelete?: () => void;
};

export const TrustedBeneficiaryItem: React.FC<TrustedBeneficiaryItemProps> = ({ id, onDelete }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <MerchantLogo id={id} className={classes.logo} />
            <Dismiss24Regular onClick={onDelete} className={classes.delete} />
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: 'auto 24px',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground3,
        borderRadius: tokens.borderRadiusMedium,
        padding: `${tokens.spacingVerticalXL} ${tokens.spacingVerticalL} ${tokens.spacingVerticalXL} ${tokens.spacingVerticalXL}`,
        transition: `background-color ${tokens.durationNormal} ${tokens.curveEasyEase}`,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground3Hover,
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground3Pressed,
        },
    },
    logo: {
        height: '24px',
    },
    delete: {
        cursor: 'pointer',
    },
});
