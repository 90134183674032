import { ITelemetryInitializerHandler } from '@microsoft/applicationinsights-core-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ICustomProperties, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AxiosError } from 'axios';

import { ResponseError, sanitize } from '@zastrpay/common';

import { applicationInsights } from '../config';

const appInsights = new ApplicationInsights({
    config: {
        autoTrackPageVisitTime: false,
        disableAjaxTracking: true,
        disableCookiesUsage: true,
        enableAutoRouteTracking: false,
        extensions: [new ReactPlugin()],
        instrumentationKey: applicationInsights.instrumentationKey,

        disableExceptionTracking: !applicationInsights.enabled,
        disableFetchTracking: !applicationInsights.enabled,
        disableTelemetry: !applicationInsights.enabled,

        excludeRequestFromAutoTrackingPatterns: [/google-analytics.com\/g\/collect/, /clarity.ms\/collect/],
    },
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags ??= [];
    env.tags['ai.cloud.role'] = 'CustomerPortal';
    env.tags['ai.application.ver'] = APP_VERSION;
});

let sessionTelemetryHandler: ITelemetryInitializerHandler | undefined;

export const identifyUser = (userId?: string, sessionId?: string) => {
    if (userId) {
        appInsights.setAuthenticatedUserContext(userId, undefined, true);
    } else {
        appInsights.clearAuthenticatedUserContext();
    }

    sessionTelemetryHandler?.remove();
    sessionTelemetryHandler = appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
        env.data ??= {};

        if (userId) {
            env.data.userId = userId;
        }

        if (sessionId) {
            env.data.sessionId = sessionId;
        }
    });
};

export const resetUser = () => {
    appInsights.clearAuthenticatedUserContext();
    sessionTelemetryHandler?.remove();
};

export const logError = (error: Error) => {
    let properties: ICustomProperties = {};

    if (error instanceof ResponseError) {
        properties.requestUrl = error.requestUrl;
        properties.requestMethod = error.requestMethod;

        properties.responseCode = error.code;
        properties.responseStatus = error.responseCode;

        properties.correlationId = error.correlationId;
    } else if (error instanceof AxiosError) {
        properties.requestUrl = error.response?.config?.url;
        properties.requestMethod = error.response?.config?.method;

        properties.responseCode = error.response?.data?.code;
        properties.responseStatus = error.response?.status;

        properties.errorCode = error.code;
    }

    properties = sanitize(properties) as ICustomProperties;

    console.error(error, properties);
    appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error, properties: properties });
};
