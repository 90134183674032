import { Caption1, makeStyles, Subtitle2 } from '@fluentui/react-components';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BrandedQrCode, Dialog, FormattedCurrency, Link, TimerProgressBar } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { MerchantLogo } from '../merchants/MerchantLogo';
import { TransactionIntent } from './models';

export type TransactionIntentItemProps = {
    transactionIntent: TransactionIntent;
};

const EXPIRATION_DURATION = 15 * 60;

export const TransactionIntentItem: React.FC<TransactionIntentItemProps> = ({ transactionIntent }) => {
    const classes = useStyles();
    const { t } = useTranslation('portal');

    const amount = (transactionIntent.type === 'PassthroughWithdrawal' ? -1 : 1) * transactionIntent.amount;

    const [codeOpen, setCodeOpen] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    return (
        <div className={classes.root}>
            <Subtitle2>{t('transactionIntent.item.type', { context: transactionIntent.type })}</Subtitle2>
            <Subtitle2 align="end">
                <FormattedCurrency currency={transactionIntent.currency} value={amount} format="sign" />
            </Subtitle2>
            <Caption1 className={classes.details}>
                {transactionIntent.merchantId && (
                    <>
                        <MerchantLogo id={transactionIntent.merchantId} className={classes.merchantLogo} />
                        <span className={classes.detailsSeparator} />
                    </>
                )}
                <Link onClick={() => setCodeOpen(true)} className={classes.show}>
                    {t('transactionIntent.item.showCode')}
                </Link>
            </Caption1>
            <Dialog
                open={codeOpen}
                onOpenChange={setCodeOpen}
                align="bottom"
                title={
                    <>
                        <Trans t={t} i18nKey="transactionIntent.item.title" context={transactionIntent.type} />
                        <br />
                        <FormattedCurrency currency={transactionIntent.currency} value={transactionIntent.amount} format="sign" />
                    </>
                }
            >
                <div className={classes.codePreview}>
                    <MerchantLogo id={transactionIntent.merchantId} className={classes.dialogDetailsMerchantLogo} />
                    <BrandedQrCode content={transactionIntent.code ?? transactionIntent.id} style={{ maxWidth: '80%' }} />
                    <TimerProgressBar
                        startOn={transactionIntent.createdOn}
                        duration={EXPIRATION_DURATION}
                        onProgress={setRemainingTime}
                        title={t('transactionIntent.item.expiresIn', { remainingTime: Math.ceil(remainingTime / 60) })}
                    />
                </div>
            </Dialog>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalL}`,
        borderRadius: tokens.borderRadiusMedium,
        gap: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalM}`,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground5,
    },
    details: {
        display: 'inline-flex',
        alignItems: 'center',
        gridColumnStart: '1',
        gridColumnEnd: 'span 2',
    },
    detailsSeparator: {
        display: 'inline-block',
        minWidth: '4px',
        width: '4px',
        height: '4px',
        borderRadius: '2px',
        margin: '2px 8px',
        backgroundColor: tokens.colorNeutralForeground1,
    },
    merchantLogo: {
        height: tokens.lineHeightBase200,
    },
    show: {
        fontSize: 'inherit',
        whiteSpace: 'nowrap',
    },
    codePreview: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: tokens.spacingVerticalM,
    },
    dialogDetailsMerchantLogo: {
        height: tokens.lineHeightBase400,
    },
});
