import { makeStyles, Title3 } from '@fluentui/react-components';

import { tokens } from '@zastrpay/theme';

export type SettingsHeaderProps = {
    title: string;
    icon: React.ReactElement;
};

export const SettingsHeader: React.FC<SettingsHeaderProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.headerIcon}>{props.icon}</div>
            <Title3>{props.title}</Title3>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalM,
        padding: `0 ${tokens.spacingHorizontalS} ${tokens.spacingVerticalS} ${tokens.spacingHorizontalS}`,
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
    },
    headerIcon: {
        color: tokens.customPaletteGreen,
        fontSize: tokens.fontSizeHero900,
        padding: `${tokens.spacingVerticalS} 0`,
    },
});
