import { Caption1, Card, Title2 } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Account, get } from '@zastrpay/accounts';
import { useAuth } from '@zastrpay/auth';
import { FormattedCurrency } from '@zastrpay/components';

export const BalanceWidget: React.FC = () => {
    const { state, customerId } = useAuth();
    const { t } = useTranslation('portal');

    const [account, setAccount] = useState<Account>();

    useEffect(() => {
        if (state === 'authenticated') {
            get(customerId).then(setAccount);
        }
    }, [state, customerId]);

    if (!account) {
        return null;
    }

    return (
        <Card size="large">
            <div>
                <Title2 block>
                    <FormattedCurrency value={account.balance} currency={account.currency} format="sign" />
                </Title2>
                <Caption1>{t('account.balance.widget.title')}</Caption1>
            </div>
        </Card>
    );
};
