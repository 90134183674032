import { api } from '@zastrpay/common';

import { endpoints } from '../config';
import { Merchant } from './models';

const SERVICE_PREFIX = 'merchants';
const SERVICE_QRY_URL = `${endpoints.merchQryh}/${SERVICE_PREFIX}`;

export const get = (id: string): Promise<Merchant> => {
    return api.get<Merchant>(`${SERVICE_QRY_URL}/${id}`, { loader: false });
};
