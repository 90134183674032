import { makeStyles } from '@fluentui/react-components';
import { CallRegular, MailRegular, PasswordRegular, PersonRegular, SettingsRegular, ShieldLockRegular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { trackPage } from '@zastrpay/analytics';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { SettingsHeader } from './SettingsHeader';
import { SettingsMenuItem } from './SettingsMenuItem';

export const Settings: React.FC = () => {
    const { t } = useTranslation('portal');
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        trackPage('settings');
    }, []);

    return (
        <Page align="fill" className={classes.openAnimation}>
            <SettingsHeader title={t('menu.top.settings')} icon={<SettingsRegular />} />

            <div className={classes.menu}>
                <SettingsMenuItem
                    icon={<ShieldLockRegular />}
                    title={t('settings.trustedBeneficiaries.title')}
                    onClick={() => navigate('/settings/trusted-beneficiaries')}
                />
                <SettingsMenuItem
                    icon={<MailRegular />}
                    title={t('settings.changeEmail.title')}
                    onClick={() => navigate('/settings/change-email')}
                />
                <SettingsMenuItem
                    icon={<PasswordRegular />}
                    title={t('settings.changePin.title')}
                    onClick={() => navigate('/settings/change-pin')}
                />
                <SettingsMenuItem
                    icon={<CallRegular />}
                    title={t('settings.changePhone.title')}
                    onClick={() => navigate('/settings/change-phone')}
                />
                <SettingsMenuItem
                    icon={<PersonRegular />}
                    title={t('settings.personalDetails.title')}
                    onClick={() => navigate('/settings/personal-details')}
                />
            </div>
        </Page>
    );
};

const useStyles = makeStyles({
    menu: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: tokens.spacingVerticalS,
    },
    openAnimation: {
        animationDuration: '0.5s',
        animationTimingFunction: 'ease',
        animationName: [
            {
                from: { opacity: 0 },
                to: { opacity: 1 },
            },
            {
                from: { transform: 'translate(0px, 100px)' },
                to: { transform: 'translate(0px, 0px)' },
            },
        ],
    },
});
